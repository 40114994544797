import type { Nullish } from "~/types/Helpers";
import type { InternetOffer, TvOffer } from "~/types/Offers";

export function mergeVariants<T>(offer: Nullish<TvOffer> | Nullish<InternetOffer>) {


  if (!offer || !offer.variants) {
    return [offer] as T[]
  }

  const { variants: variants, ...offerWithoutVariants } = offer;

  return [offerWithoutVariants, ...variants.map(x => ({ ...offerWithoutVariants, ...x }))] as T[]

}
